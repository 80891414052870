import request from '@/api/request.js';
// 订单列表
export function getOrderList(data) {
    return request({
        url: 'business/api/order/list',
        method: 'post',
        data: data
    });
}
// 订单详情
export function getOrderInfo(data) {
    return request({
        url: 'business/api/order/orderInfo',
        method: 'post',
        data: data
    });
}
// 操作订单
export function orderAction(data) {
    return request({
        url: 'business/api/order/orderAction',
        method: 'post',
        data: data
    });
}
// 取消订单
export function cancelOrder(data) {
    return request({
        url: 'business/api/order/cancelOrder',
        method: 'post',
        data: data
    });
}


// 订单退货/退款操作
export function refundOrder(data) {
    return request({
        url: 'business/api/order/refund',
        method: 'post',
        data: data
    });
}

